import React, { Component } from "react";
import "./Style.css";
import FullpageDiv from "./FullpageDiv";
import { Element } from "react-scroll";
import OC from "./_files/PMC2024-Organizing Committee.jpg"

class FourthSection extends Component {


  render() {
    return (
      <FullpageDiv id="FourthSection" className="Content4">
        <Element name="org" className="element">
        <div>
          <p className="orgParagraph">
          <label className="L2">Organizing Committee</label> <br/>
          <img src={OC} alt="PMC Conference 2024 By Prof. Kambiz Ebrahimi" className="OC"/>
          </p>
        </div>
        </Element>
      </FullpageDiv>
    );
  }
}

export default FourthSection;
