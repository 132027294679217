import './App.css';
import FirstSection from './_sections/FirstSection';
import SecondSection from './_sections/SecondSection';
import ThirdSection from './_sections/ThirdSection';
import FourthSection from './_sections/FourthSection';
import FifthSection from './_sections/FifthSection';
import SixthSection from './_sections/SixthSection';
import SeventhSection from './_sections/SeventhSection';
import React  from 'react';

function App() {
  return (
    <div className="App">
             <FirstSection></FirstSection>
             <SecondSection></SecondSection>
             <ThirdSection></ThirdSection>
             <FourthSection></FourthSection>
             <FifthSection></FifthSection>
             <SeventhSection></SeventhSection>

             <SixthSection></SixthSection>
    </div>
  );
}

export default App;
