import React, { Component } from "react";
import "./Style.css";
import FullpageDiv from "./FullpageDiv";
import { Element } from "react-scroll";

class ThirdSection extends Component {


  render() {
    return (
      <FullpageDiv id="ThirdSection" className="Content3">
        <Element name="Submission" className="element">
        <div className="ThirdBottom">
              <p className="LeftParagraphB">
              <label className="L2">Submission</label> <br/><br/>
              Papers must be submitted in PDF format. 
              Detailed submission instructions refer to the conference website. 
              <br />              <br />
              Submit your paper as well as inquiries to the meeting email: <a className="alink" href="mailto:PMC2024@dlut.edu.cn" >PMC2024@dlut.edu.cn</a>
              <br />              <br />
              All quality papers will be recommended to IJPT for further publishing.
              <br />      <br />

              <label className="L2">Registration</label> <br/><br/>
              Please email your paper abstract to: <a className="alink" href="mailto:PMC2024@dlut.edu.cn" >PMC2024@dlut.edu.cn</a>
              <br />
              The registration email should provide the essential information: title, authors & affiliations, abstract.
              <br />              <br />

              The deadline for paper submission is August 1, 2024. In the meantime, paper registration is open for acceptance as well.
              </p>
        </div>
        </Element>
      </FullpageDiv>
    );
  }
}

export default ThirdSection;
