import React, { Component } from "react";
import "./Style.css";
//import data from "./data.json"
//import { SocialIcon } from "react-social-icons";
import FullpageDiv from "./FullpageDiv";
import { Link , Element} from 'react-scroll';

class FirstSection extends Component {

  MouseOver = (id,bgcolor,color) => {
    document.getElementById(id).style.backgroundColor=bgcolor;
    document.getElementById(id).style.color=color;
  }

  render() {
    return (
      <FullpageDiv id="FirstSection" className="Content">
        
        <div className="Header"> 
            <div className="LeftHeader"><lable className="TopLable">PMC7</lable></div>
            <div className="RightHeader">
              <ul>
                <Link 
                  activeClass="active" 
                  to="home" 
                  spy={true} 
                  smooth={true} 
                  offset={-100} 
                  duration={2000} 
                >  
                <li><lable className="TopLink">Home</lable></li>
                </Link>
                <Link 
                  activeClass="active" 
                  to="Contact" 
                  spy={true} 
                  smooth={true} 
                  offset={-100} 
                  duration={2000} 
                >  
                <li><lable className="TopLink">Contact Us</lable></li>
                </Link>
                <li><a href="_files/Call_for_Paper_PMC7.pdf" target="/blank" className="TopLink">Call for papers</a></li>
                <li><a href="http://pmc-conference.com/2022" className="TopLink" target="/blank">Last Conference: PMC 2022</a></li>
              </ul>
            </div>
        </div>
        <div className="FirstContainer">
        
            <div className="LeftC">
            <Element name="home" className="element">
              <p className="LeftParagraph">
                <label className="L1">PMC7 - 2024</label>
                <br className="PCBR"/>
                <br className="PCBR"/>
                <br className="PCBR"/>
                <br className=""/>
                <label className="L2">7th  Biennial International Conference on</label> 
                <br />
                <label className="L1">Powertrain Modelling and Control for Decarbonized, Electrified and Smart Mobility</label>
                <br />
                <br /> 
              </p>
            </Element>

            </div>
            <div className="RightC">
              <p>
              <label className="L1">30th Oct - 1st Nov 2024 </label>  
              <label className="L2">Dalian University of Technology - China</label> 
              </p>
              
            </div>
        </div>


        <div className="Bottom">
                
                <Link 
                  activeClass="active" 
                  to="intro" 
                  spy={true} 
                  smooth={true} 
                  offset={-100} 
                  duration={500} 
                >  
                <button className="HeaderBtn" id="intro" onMouseOver={() => {this.MouseOver("intro","#0b5eda","white");}} onMouseOut={() => {this.MouseOver("intro","","");}}>Introduction</button>
                </Link>
                <Link 
                  activeClass="active" 
                  to="Submission" 
                  spy={true} 
                  smooth={true} 
                  offset={-100} 
                  duration={2000} 
                >
                <button className="HeaderBtn" id="submission" onMouseOver={() => {this.MouseOver("submission","#0b5eda","white");}} onMouseOut={() => {this.MouseOver("submission","","");}}>Submission</button>
                </Link>
                <Link 
                  activeClass="active" 
                  to="org" 
                  spy={true} 
                  smooth={true} 
                  offset={-100} 
                  duration={2000} 
                >
                <button className="HeaderBtn" id="organ" onMouseOver={() => {this.MouseOver("organ","#0b5eda","white");}} onMouseOut={() => {this.MouseOver("organ","","");}}>Organizing Committee</button>
                </Link>
                <Link 
                  activeClass="active" 
                  to="KD" 
                  spy={true} 
                  smooth={true} 
                  offset={-150} 
                  duration={2000} 
                >
                <button className="HeaderBtn" id="keydates" onMouseOver={() => {this.MouseOver("keydates","#0b5eda","white");}} onMouseOut={() => {this.MouseOver("keydates","","");}}>Key Dates</button>
                </Link>
                <Link 
                  activeClass="active" 
                  to="Dalian" 
                  spy={true} 
                  smooth={true} 
                  offset={-50} 
                  duration={2000} 
                >
                <button className="HeaderBtn" id="venue" onMouseOver={() => {this.MouseOver("venue","#0b5eda","white");}} onMouseOut={() => {this.MouseOver("venue","","");}}>Venue</button>
                </Link>
                <button className="HeaderBtn" id="cfp" onMouseOver={() => {this.MouseOver("cfp","#0b5eda","white");}} onMouseOut={() => {this.MouseOver("cfp","","");}} >
                <a href="_files/Call_for_Paper_PMC7.pdf" target="/blank" className="btnlink">
                  Call for papers
                </a>  
                  </button>
                
        </div>


      </FullpageDiv>
    );
  }
}

export default FirstSection;
