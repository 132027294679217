import React, { Component } from "react";
import "./Style.css";
import FullpageDiv from "./FullpageDiv";
import { Element } from "react-scroll";
import pmcLogo from './_files/PMC2024.png';

class SecondSection extends Component {


  render() {
    return (
      <FullpageDiv id="FirstSection" className="Content2">
        <Element name="intro" className="element">
        <div className="FirstContainer">
            <div className="LeftBC">
              <p className="LeftParagraphB">
              <label className="L2">Introduction</label> <br/>
              The 2024 Biennial International Conference on Powertrain Modelling and Control (PMC7) for Decarbonized, Electrified and Smart Mobility is focusing on latest advances and developments in new generation of hydrogen, electric, and hybrid powertrains, covering dynamics, control, testing, characterization, calibration, and beyond. 
              <br />
              The format will be both in-person and online. 
              <br />
              The event will open up presentations and discussions on the solutions to challenging mobility technologies among the development of the following frontiers, but not limited to:
              </p>
            </div>

            <div className="RightBC">
              <img src={pmcLogo} alt="PMC Conference 2024" className="RightBCPic"/>
            </div>
        </div>
        </Element>
      </FullpageDiv>
    );
  }
}

export default SecondSection;
