import React, { Component } from "react";
import "./Style.css";
import FullpageDiv from "./FullpageDiv";
import { Element } from "react-scroll";

class SeventhSection extends Component {

  componentDidMount () {
    document.getElementById("title2").style.color="white";
    document.getElementById("link1").style.color="white";
    document.getElementById("link2").style.color="white";

  }

  render() {
    return (
      <FullpageDiv id="SeventhSection" className="Content7">
       <Element name="Contact" className="element">
        <div>
        <p className="orgParagraph">
        <label className="L2" id="title2">Contact</label> <br />
        <p className="ContactDetails">
          PMC (https://pmc-conference.com) <br />
          will invite keynotes, organize workshop and panels. <br />
          Best papers will be selected for award. <br /> <br />
          All the registration shall go to <br />
          <a id="link1" href="mailto:PMC2024@dlut.edu.cn">PMC2024@dlut.edu.cn </a><br />
          with further inquiry contact: +86-15942604160 (Ms. Zhao). <br /> <br />
          This registration is also valid for E-COSM2024 (<a id="link2" href="https://ecosm2024.dlut.edu.cn/">https://ecosm2024.dlut.edu.cn/</a>).
          </p>
        </p>       
        </div>
        </Element>
      </FullpageDiv>
    );
  }
}

export default SeventhSection;


