import React, { Component } from "react";
import "./Style.css";
import FullpageDiv from "./FullpageDiv";
import { Element } from "react-scroll";

class FifthSection extends Component {

  componentDidMount () {
    document.getElementById("title").style.color="white";
  }

  render() {
    return (
      <FullpageDiv id="FifthSection" className="Content5">
        <Element name="KD" className="element">
        <div> 
        <p className="orgParagraph">
        <label className="L2" id="title">Key Dates</label>
        </p>       
          <div className="Fifth">
              <div className="FifthSlice">Paper Registration <br />June 1, 2024</div>
              <div className="FifthSlice">Paper Submission <br />August 1, 2024</div>
              <div className="FifthSlice">Notification <br /> September 1,2024</div>
              <div className="FifthSlice">Final Paper <br />September 15, 2024</div>
          </div>
        </div>
        </Element>
      </FullpageDiv>
    );
  }
}

export default FifthSection;

